<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
	<div :class="{ 'px-3': isSmAndDown }">
		<v-row class="mt-1 mb-2">
			<v-col class="px-0">
				<div class="d-flex align-center">
					<router-link
						:to="{ name: 'mypage-license' }"
						class="text-decoration-none"
					>
						<h3 style="font-weight: 500">신청보드</h3>
					</router-link>
					<span class="mx-1">&#62;</span>
					<h3>신청하기</h3>
				</div>
			</v-col>
		</v-row>
		<v-row v-if="isEducationLicense">
			<my-page-license-education-information
				@is-open-event-detail-dialog="isOpenEventDetailDialog = $event"
			/>
		</v-row>
		<v-row>
			<my-page-application-board-event-application-information
				:profile="profile"
				:event="event"
			/>
			<v-col cols="12" md="12" sm="12" class="pt-0 px-0">
				<v-card class="py-4 px-7">
					<v-list>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									<h4>가격 정보</h4>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-3" />
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs text-wrap">
									가격
								</v-list-item-subtitle>
								<v-list-item-title v-if="event" class="text-wrap">
									<div v-if="event.amount > 0">
										<span class="amount primary--text">
											{{ event.amount | commaFormat }} 원
										</span>
										<span
											:class="{ 'd-block text-sm': isSmAndDown }"
											v-if="isEducationLicense"
										>
											(교육비 24만원 + 검정료 4만원 + 교재비 1만원)
										</span>
									</div>
									<div v-else>
										<div class="mb-5">무료</div>
										<v-btn
											class="button apply mb-1"
											color="primary"
											@click="applyEvent(event)"
											:loading="isLoading"
											:disabled="
												isRetake ||
												isApplied ||
												event.canApply.canApplyDisplayTitle === '접수 마감'
											"
											v-if="!isApplied"
										>
											{{
												event.canApply.canApplyDisplayTitle ||
												`${appliedWord}하기`
											}}
										</v-btn>
										<div v-else>
											<v-btn
												class="button apply"
												color="error"
												:disabled="
													!canCancel ||
													hasUserPaid ||
													event.canApply.canApplyDisplayTitle === '접수 마감'
												"
												@click="cancelEvent(event)"
											>
												{{ event.canCancel.canCancelDisplayTitle }}
											</v-btn>
										</div>
									</div>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-card>
			</v-col>
			<v-col cols="12" md="12" sm="12" class="pt-0 px-0" v-if="isPaidItem">
				<v-card class="py-4 px-7">
					<v-list>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									<h4>결제 수단 선택</h4>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-4" />
						<v-list-item>
							<v-list-item-content>
								<v-radio-group hide-details v-model="paymentMethod" row>
									<v-radio
										v-for="(payment, index) in paymentGroup"
										:key="`payment-${index}`"
										:label="payment.name"
										:value="payment.value"
									>
										<template slot="label">
											<span
												class="d-flex align-center"
												v-if="
													paymentMethods.KAKAOPAY &&
													payment.value === paymentMethods.KAKAOPAY.value
												"
											>
												<img
													height="28"
													:src="
														require(`@/assets/logo/logo-${payment.value}.svg`)
													"
												/>
											</span>
											<span
												class="d-flex align-center"
												v-else-if="
													paymentMethods.NAVERPAY &&
													payment.value === paymentMethods.NAVERPAY.value
												"
											>
												<img
													height="24"
													:src="
														require(`@/assets/logo/logo-${payment.value}.png`)
													"
												/>
											</span>
											<span class="d-flex align-center" v-else>
												{{ payment.name }}
											</span>
										</template>
									</v-radio>
								</v-radio-group>
							</v-list-item-content>
						</v-list-item>
					</v-list>
					<v-card-text class="px-0 pb-0">
						<p v-if="hasUserPaid">
							<strong>취소 문의는 상담문의를 통해서 요청해주세요</strong>
						</p>
						<p v-else>
							결제완료 전 취소시
							<strong>1시간 이후</strong>
							자동 취소 됩니다.
						</p>
					</v-card-text>
					<v-card-actions class="px-0 d-flex flex-column">
						<div v-if="isRetake" class="button-wrapper mb-1 text-center">
							<strong>재수강 신청은 결제가 필요하지 않습니다</strong>
							<div v-if="canCancel">
								<v-btn
									class="button apply"
									color="error"
									:disabled="!canCancel || hasUserPaid"
									@click="cancelEvent(event)"
									v-if="isApplied"
								>
									{{ event.canCancel.canCancelDisplayTitle }}
								</v-btn>
							</div>

							<v-btn
								class="button apply mb-1"
								color="primary"
								@click="applyRetakeEvent(event)"
								v-else
							>
								신청하기
							</v-btn>
						</div>
						<div class="button-wrapper" v-else>
							<v-btn
								class="button apply mb-1"
								color="primary"
								@click="applyEvent(event)"
								:loading="isLoading"
								:disabled="
									isRetake ||
									event.canApply.canApplyDisplayTitle === '접수 마감'
								"
								v-if="isPaidItem && !hasUserPaid"
							>
								{{
									isApplied
										? '결제하기'
										: event.canApply.canApplyDisplayTitle ||
										  `${appliedWord}하기`
								}}
							</v-btn>
							<v-btn class="button apply mb-1" disabled v-else>
								{{ event.canCancel.canCancelDisplayTitle }}
							</v-btn>

							<v-btn
								class="button apply mb-1"
								color="secondary"
								v-if="
									!hasUserPaid &&
									!isApplied &&
									event.canApply.canApplyDisplayTitle === '재수강 오픈전'
								"
							>
								재수강자는
								{{ event.startRetakeRegistrationDate | _dateFormat }}
								부터 신청 가능합니다
							</v-btn>

							<div v-if="canCancel">
								<v-btn
									class="button apply"
									color="error"
									:disabled="!canCancel || hasUserPaid"
									@click="cancelEvent(event)"
									v-if="isApplied"
								>
									{{ event.canCancel.canCancelDisplayTitle }}
								</v-btn>
							</div>
						</div>
					</v-card-actions>
					<v-list>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									<h4>주의사항 및 환불규정</h4>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-4" />
					</v-list>
					<v-card-text class="px-0">
						<h4>주의사항</h4>
						<ul class="mb-5">
							<li>
								상황에 따라 사전 공지 없이 할인이 조기 마감되거나 연장될 수
								있습니다.
							</li>
							<li>
								천재지변, 폐업 등 서비스 중단이 불가피한 상황에는 서비스가
								종료될 수 있습니다.
							</li>
							<li>
								커리큘럼은 제작 과정에서 일부 추가, 삭제 및 변경될 수 있습니다.
							</li>
						</ul>
						<h4>환불규정</h4>
						<ul class="mb-2">
							<li>사전 접수 기간 중: 전액 환불</li>
							<li>
								사전접수 기간 종료 후 ~ 교육 1일 전: 등록비 5% 내 공제 후 환불
							</li>
							<li>교육 당일 및 이후: 환불 불가</li>
						</ul>
						<p>
							* 보다 자세한 환불 규정은 홈페이지 이용 약관 환불 정책에서 확인
							가능합니다.
						</p>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<my-page-application-board-event-detail-dialog
			:is-open-event-detail-dialog.sync="isOpenEventDetailDialog"
		/>
	</div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import store from '@/store'
import { useRouter, getVuetify } from '@core/utils'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'
import { eventCategory, paymentMethods, applicationStatus } from '@/helpers'

import EventService from '@/services/EventService'

import MyPageLicenseEducationInformation from '../MyPageApplicationBoard/MyPageLicense/MyPageLicenseEducationInformation.vue'
import MyPageApplicationBoardEventApplicationInformation from './MyPageApplicationBoardEventApplicationInformation.vue'
import MyPageApplicationBoardEventDetailDialog from './MyPageApplicationBoardEventDetailDialog.vue'

export default {
	components: {
		MyPageLicenseEducationInformation,
		MyPageApplicationBoardEventDetailDialog,
		MyPageApplicationBoardEventApplicationInformation,
	},
	setup() {
		const $vuetify = getVuetify()
		const { route, router } = useRouter()
		const event = ref({})
		const eventParticipations = ref([])
		const isLoading = ref(false)
		const educationLicenseCategory = ref(eventCategory.EDUCATION_LICENSE.value)
		const profile = computed(() => store.getters['user/getMeDetail'])
		const eventId = route.value.params.eventId
		const paymentMethod = ref('card')
		const paymentGroup = Object.values(paymentMethods)
		const isOpenEventDetailDialog = ref(false)

		const getMeEvent = async () => {
			try {
				const [data] = await EventService.getMeEvent(eventId)

				if (!data.visible || !data.isActive) {
					router.go(-1)
					return
				}

				event.value = data
			} catch (e) {
				warningSwal('잘못된 요청입니다')
				router.go(-1)
			}
		}
		getMeEvent()

		const getEventParticipations = async () => {
			const data = await EventService.getEventParticipations(eventId)
			eventParticipations.value = data ? data[0] : []
		}
		getEventParticipations()

		const canApply = computed(() => {
			return (
				event.value && event.value.canApply && event.value.canApply.canApply
			)
		})

		const canCancel = computed(() => {
			return (
				event.value && event.value.canCancel && event.value.canCancel.canCancel
			)
		})

		const isEducationLicense = computed(() => {
			return (
				event.value && event.value.category === educationLicenseCategory.value
			)
		})

		const isApplied = computed(() => {
			return (
				event.value &&
				event.value.myEventParticipation &&
				event.value.myEventParticipation.status ===
					applicationStatus.APPLIED.value
			)
		})

		const isPaidItem = computed(() => {
			return event.value && event.value.amount > 0
		})

		const hasUserPaid = computed(() => {
			return (
				eventParticipations.value &&
				eventParticipations.value.status === applicationStatus.PAID.value
			)
		})

		const isRetake = computed(() => {
			return (
				event.value.canApply?.retake ||
				(eventParticipations.value && eventParticipations.value.retake)
			)
		})

		const appliedWord = computed(() => {
			return isPaidItem.value ? (isRetake.value ? '신청' : '결제') : '신청'
		})

		const cancelEvent = async event => {
			const confirm = await confirmSwal(
				` ${event.title} 이벤트 신청을 취소 하시겠습니까?`,
			)
			if (confirm.isConfirmed) {
				try {
					await EventService.cancelEvent(event.id)
					await successSwal({
						html: '신청 취소가 완료되었습니다',
						allowOutsideClick: false,
					})
					router.push({
						name: 'mypage-license',
					})
				} catch (e) {
					await warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '신청을 취소하는데 문제가 발생했습니다.',
					)
					router.go()
				}
			}
		}

		const createPaymentEvent = async payload => {
			try {
				await EventService.createPaymentEvent(payload)
				const confirm = await successSwal({
					title: '결제 성공',
					allowOutsideClick: false,
				})
				if (confirm.isConfirmed) {
					router.push({
						name: 'mypage-application-event-payment-complete',
						params: {
							eventId: payload.id,
						},
					})
				}
			} catch (e) {
				console.error(e)
			}
		}

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		const callback = async response => {
			const { success, error_msg } = response
			const payload = {
				id: eventId,
				pgResponse: JSON.stringify(response),
			}

			if (success) {
				await createPaymentEvent(payload)
			} else {
				await EventService.cancelEvent(payload.id)
				const failConfirmed = await warningSwal({
					title: '결제 실패',
					html: error_msg,
				})
				if (failConfirmed.isConfirmed) {
					router.go()
				}
			}
		}

		const requestPay = async () => {
			isLoading.value = true
			try {
				const { IMP } = window

				IMP.init(process.env.VUE_APP_MERCHANT_ID_CODE)

				const merchantUid = `${
					eventParticipations.value.uuid
				}${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}`

				const data = {
					pg: process.env.VUE_APP_PG_CODE,
					pay_method: paymentMethod.value,
					escrow: undefined,
					merchant_uid: merchantUid,
					name: event.value.title,
					amount: event.value.amount,
					buyer_name: profile.value.name,
					buyer_tel: profile.value.phoneNumber,
					buyer_email: profile.value.email,
					buyer_addr: '',
					buyer_postcode: '',
					niceMobileV2: true,
					m_redirect_url:
						process.env.VUE_APP_DOMAIN_URL +
						`/mypage/application/events/${eventId}`,
				}

				if (paymentMethod.value === 'card') {
					data.display = {
						card_quota: 0,
					}
				}

				IMP.request_pay(data, callback)
			} catch (e) {
				if (e) {
					await EventService.cancelEvent(eventId)
				}
			} finally {
				isLoading.value = false
			}
		}

		const applyEvent = async item => {
			if (isApplied.value) {
				if (isPaidItem.value) {
					await requestPay()
				}
			} else {
				const confirm = await confirmSwal(
					`${item.title}<br /> 교육을 ${appliedWord.value} 하시겠습니까?`,
				)
				if (confirm.isConfirmed) {
					try {
						await EventService.applyEvent(item.id)
						if (isPaidItem.value) {
							await requestPay()
						} else {
							const successConfirm = await successSwal({
								html: `${appliedWord.value}이 완료되었습니다`,
								allowOutsideClick: false,
							})
							if (successConfirm.isConfirmed) {
								router.push({
									name: 'mypage-application-event-payment-complete',
									params: {
										eventId: item.id,
									},
								})
							}
						}
					} catch (e) {
						await warningSwal(
							e.response.status === 400
								? e.response.data.message
								: `${appliedWord.value}하는데 문제가 발생했습니다.`,
						)
						router
							.push({
								name: 'mypage-application-event',
								params: { eventId: eventId },
							})
							.catch(() => {})
					}
				}
			}
		}

		const applyRetakeEvent = async item => {
			const confirm = await confirmSwal(
				`${item.title}<br /> 교육을 ${appliedWord.value} 하시겠습니까?`,
			)
			if (confirm.isConfirmed) {
				try {
					await EventService.applyEvent(item.id)
					const successConfirm = await successSwal(
						`${appliedWord.value}이 완료되었습니다`,
					)
					if (successConfirm.isConfirmed) {
						router.go()
					}
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: `${appliedWord.value}하는데 문제가 발생했습니다.`,
					)
				}
			}
		}

		watch(
			() => route.value.query.imp_success,
			async currentValue => {
				if (currentValue && currentValue !== 'undefined') {
					const isSuccess = currentValue === 'true' ? true : false
					if (isSuccess) {
						const payload = {
							id: eventId,
							pgResponse: JSON.stringify({}),
						}
						await createPaymentEvent(payload)
					} else {
						await warningSwal('결제를 처리하는데 문제가 발생했습니다!')
						router.push({
							name: 'mypage-application-event',
							params: { eventId: eventId },
						})
					}
				}
			},
			{
				immediate: true,
			},
		)

		return {
			event,
			profile,
			canApply,
			canCancel,
			isRetake,
			isApplied,
			isLoading,
			isPaidItem,
			appliedWord,
			hasUserPaid,
			isSmAndDown,
			paymentGroup,
			paymentMethod,
			paymentMethods,
			isEducationLicense,
			eventParticipations,
			isOpenEventDetailDialog,
			educationLicenseCategory,

			applyEvent,
			applyRetakeEvent,
			cancelEvent,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-list-item {
	padding: 0;

	&__subtitle {
		color: #aaaaaf !important;
	}

	&__title {
		span.body-content {
			::v-deep {
				p {
					margin-bottom: 2px;
				}
			}
		}
	}

	span.amount {
		font-size: 24px;
		font-weight: 900;
		letter-spacing: -0.5px;
	}
}

.button-wrapper {
	width: 100%;
}

.button {
	display: block;
	width: 100%;
	max-width: 100%;
	min-height: 48px;
	margin-left: 0 !important;
	font-weight: 900;

	::v-deep {
		.v-btn__content {
			height: 100% !important;
		}
	}
}
</style>
