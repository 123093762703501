<template>
	<v-col cols="12" md="12" sm="12" class="pt-0 px-0">
		<v-card class="py-4 px-7">
			<v-list>
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title>
							<h4>신청 정보</h4>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-divider class="my-3" />
				<v-list-item two-line>
					<v-list-item-content>
						<v-list-item-subtitle class="text-xs">타이틀</v-list-item-subtitle>
						<v-list-item-title v-if="event && event.title">
							{{ event.title }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item two-line>
					<v-list-item-content>
						<v-list-item-subtitle class="text-xs">내용</v-list-item-subtitle>
						<v-list-item-title v-if="event && event.body" class="text-wrap">
							<template v-if="event.id === 34">
								<img
									:src="require('@/assets/images/notice/all_ai.png')"
									style="width: 100%"
								/>
							</template>
							<span v-html="event.body" class="body-content"></span>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item two-line>
					<v-list-item-content>
						<v-list-item-subtitle>일정</v-list-item-subtitle>
						<v-list-item-title v-if="event && event.eventAt">
							{{ event.eventAt | dateSiFormat }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item two-line>
					<v-list-item-content>
						<v-list-item-subtitle>장소</v-list-item-subtitle>
						<v-list-item-title v-if="event && event.location">
							{{ event.location }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item two-line>
					<v-list-item-content>
						<v-list-item-subtitle class="text-xs">
							신청 마감일
						</v-list-item-subtitle>
						<v-list-item-title v-if="event">
							<span v-if="event.endRegistrationDate">
								{{ event.endRegistrationDate | _dateFormat }}
							</span>
							<span v-else>-</span>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item two-line>
					<v-list-item-content>
						<v-list-item-subtitle class="text-xs">
							모집 인원 (정원)
						</v-list-item-subtitle>
						<v-list-item-title v-if="event && event.maxParticipants">
							<strong>
								{{
									[3, 4, 5, 6, 8, 9].includes(event.id)
										? '-'
										: event.presentParticipants
								}}
								명
							</strong>
							({{ event.maxParticipants }}명)
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<v-divider class="my-3" />
			<v-list-item two-line>
				<v-list-item-content>
					<v-list-item-subtitle class="text-xs">이름</v-list-item-subtitle>
					<v-list-item-title>{{ profile.name }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item two-line>
				<v-list-item-content>
					<v-list-item-subtitle class="text-xs">이메일</v-list-item-subtitle>
					<v-list-item-title>{{ profile.email }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item two-line>
				<v-list-item-content>
					<v-list-item-subtitle class="text-xs">
						휴대폰번호
					</v-list-item-subtitle>
					<v-list-item-title>{{ profile.phoneNumber }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-card>
	</v-col>
</template>

<script>
export default {
	props: {
		profile: {
			type: Object,
			required: true,
		},
		event: {
			type: Object,
			required: true,
		},
	},
	setup() {},
}
</script>
<style lang="scss" scoped>
.v-list-item {
	padding: 0;

	&__subtitle {
		color: #aaaaaf !important;
	}

	&__title {
		span.body-content {
			::v-deep {
				img {
					width: 100%;
				}

				p {
					margin-bottom: 2px;
				}
			}
		}
	}

	span.amount {
		font-size: 24px;
		font-weight: 900;
		letter-spacing: -0.5px;
	}
}

.button-wrapper {
	width: 100%;
}

.button {
	display: block;
	width: 100%;
	max-width: 100%;
	min-height: 48px;
	margin-left: 0 !important;
	font-weight: 900;

	::v-deep {
		.v-btn__content {
			height: 100% !important;
		}
	}
}
</style>
