<template>
	<v-row class="pb-3">
		<v-col cols="12" md="12" sm="12">
			<v-card class="py-4 px-7">
				<v-list>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title>
								<h4>교육 정보</h4>
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-divider class="my-3" />
				</v-list>

				<v-card-actions class="px-0 d-flex flex-column">
					<div class="button-wrapper">
						<v-btn
							class="button apply"
							color="primary"
							@click="$emit('is-open-event-detail-dialog', true)"
						>
							자세히 알아보기
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
export default {
	components: {},
	setup() {},
}
</script>
<style lang="scss" scoped>
.button-wrapper {
	width: 100%;
}

.button {
	display: block;
	width: 100%;
	max-width: 100%;
	min-height: 48px;
	margin-left: 0 !important;
	font-weight: 900;

	::v-deep {
		.v-btn__content {
			height: 100% !important;
		}
	}
}
</style>
