var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'px-3': _vm.isSmAndDown }},[_c('v-row',{staticClass:"mt-1 mb-2"},[_c('v-col',{staticClass:"px-0"},[_c('div',{staticClass:"d-flex align-center"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'mypage-license' }}},[_c('h3',{staticStyle:{"font-weight":"500"}},[_vm._v("신청보드")])]),_c('span',{staticClass:"mx-1"},[_vm._v(">")]),_c('h3',[_vm._v("신청하기")])],1)])],1),(_vm.isEducationLicense)?_c('v-row',[_c('my-page-license-education-information',{on:{"is-open-event-detail-dialog":function($event){_vm.isOpenEventDetailDialog = $event}}})],1):_vm._e(),_c('v-row',[_c('my-page-application-board-event-application-information',{attrs:{"profile":_vm.profile,"event":_vm.event}}),_c('v-col',{staticClass:"pt-0 px-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-card',{staticClass:"py-4 px-7"},[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('h4',[_vm._v("가격 정보")])])],1)],1),_c('v-divider',{staticClass:"my-3"}),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"text-xs text-wrap"},[_vm._v(" 가격 ")]),(_vm.event)?_c('v-list-item-title',{staticClass:"text-wrap"},[(_vm.event.amount > 0)?_c('div',[_c('span',{staticClass:"amount primary--text"},[_vm._v(" "+_vm._s(_vm._f("commaFormat")(_vm.event.amount))+" 원 ")]),(_vm.isEducationLicense)?_c('span',{class:{ 'd-block text-sm': _vm.isSmAndDown }},[_vm._v(" (교육비 24만원 + 검정료 4만원 + 교재비 1만원) ")]):_vm._e()]):_c('div',[_c('div',{staticClass:"mb-5"},[_vm._v("무료")]),(!_vm.isApplied)?_c('v-btn',{staticClass:"button apply mb-1",attrs:{"color":"primary","loading":_vm.isLoading,"disabled":_vm.isRetake ||
											_vm.isApplied ||
											_vm.event.canApply.canApplyDisplayTitle === '접수 마감'},on:{"click":function($event){return _vm.applyEvent(_vm.event)}}},[_vm._v(" "+_vm._s(_vm.event.canApply.canApplyDisplayTitle || (_vm.appliedWord + "하기"))+" ")]):_c('div',[_c('v-btn',{staticClass:"button apply",attrs:{"color":"error","disabled":!_vm.canCancel ||
												_vm.hasUserPaid ||
												_vm.event.canApply.canApplyDisplayTitle === '접수 마감'},on:{"click":function($event){return _vm.cancelEvent(_vm.event)}}},[_vm._v(" "+_vm._s(_vm.event.canCancel.canCancelDisplayTitle)+" ")])],1)],1)]):_vm._e()],1)],1)],1)],1)],1),(_vm.isPaidItem)?_c('v-col',{staticClass:"pt-0 px-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-card',{staticClass:"py-4 px-7"},[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('h4',[_vm._v("결제 수단 선택")])])],1)],1),_c('v-divider',{staticClass:"my-4"}),_c('v-list-item',[_c('v-list-item-content',[_c('v-radio-group',{attrs:{"hide-details":"","row":""},model:{value:(_vm.paymentMethod),callback:function ($$v) {_vm.paymentMethod=$$v},expression:"paymentMethod"}},_vm._l((_vm.paymentGroup),function(payment,index){return _c('v-radio',{key:("payment-" + index),attrs:{"label":payment.name,"value":payment.value}},[_c('template',{slot:"label"},[(
												_vm.paymentMethods.KAKAOPAY &&
												payment.value === _vm.paymentMethods.KAKAOPAY.value
											)?_c('span',{staticClass:"d-flex align-center"},[_c('img',{attrs:{"height":"28","src":require(("@/assets/logo/logo-" + (payment.value) + ".svg"))}})]):(
												_vm.paymentMethods.NAVERPAY &&
												payment.value === _vm.paymentMethods.NAVERPAY.value
											)?_c('span',{staticClass:"d-flex align-center"},[_c('img',{attrs:{"height":"24","src":require(("@/assets/logo/logo-" + (payment.value) + ".png"))}})]):_c('span',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(payment.name)+" ")])])],2)}),1)],1)],1)],1),_c('v-card-text',{staticClass:"px-0 pb-0"},[(_vm.hasUserPaid)?_c('p',[_c('strong',[_vm._v("취소 문의는 상담문의를 통해서 요청해주세요")])]):_c('p',[_vm._v(" 결제완료 전 취소시 "),_c('strong',[_vm._v("1시간 이후")]),_vm._v(" 자동 취소 됩니다. ")])]),_c('v-card-actions',{staticClass:"px-0 d-flex flex-column"},[(_vm.isRetake)?_c('div',{staticClass:"button-wrapper mb-1 text-center"},[_c('strong',[_vm._v("재수강 신청은 결제가 필요하지 않습니다")]),(_vm.canCancel)?_c('div',[(_vm.isApplied)?_c('v-btn',{staticClass:"button apply",attrs:{"color":"error","disabled":!_vm.canCancel || _vm.hasUserPaid},on:{"click":function($event){return _vm.cancelEvent(_vm.event)}}},[_vm._v(" "+_vm._s(_vm.event.canCancel.canCancelDisplayTitle)+" ")]):_vm._e()],1):_c('v-btn',{staticClass:"button apply mb-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.applyRetakeEvent(_vm.event)}}},[_vm._v(" 신청하기 ")])],1):_c('div',{staticClass:"button-wrapper"},[(_vm.isPaidItem && !_vm.hasUserPaid)?_c('v-btn',{staticClass:"button apply mb-1",attrs:{"color":"primary","loading":_vm.isLoading,"disabled":_vm.isRetake ||
								_vm.event.canApply.canApplyDisplayTitle === '접수 마감'},on:{"click":function($event){return _vm.applyEvent(_vm.event)}}},[_vm._v(" "+_vm._s(_vm.isApplied ? '결제하기' : _vm.event.canApply.canApplyDisplayTitle || (_vm.appliedWord + "하기"))+" ")]):_c('v-btn',{staticClass:"button apply mb-1",attrs:{"disabled":""}},[_vm._v(" "+_vm._s(_vm.event.canCancel.canCancelDisplayTitle)+" ")]),(
								!_vm.hasUserPaid &&
								!_vm.isApplied &&
								_vm.event.canApply.canApplyDisplayTitle === '재수강 오픈전'
							)?_c('v-btn',{staticClass:"button apply mb-1",attrs:{"color":"secondary"}},[_vm._v(" 재수강자는 "+_vm._s(_vm._f("_dateFormat")(_vm.event.startRetakeRegistrationDate))+" 부터 신청 가능합니다 ")]):_vm._e(),(_vm.canCancel)?_c('div',[(_vm.isApplied)?_c('v-btn',{staticClass:"button apply",attrs:{"color":"error","disabled":!_vm.canCancel || _vm.hasUserPaid},on:{"click":function($event){return _vm.cancelEvent(_vm.event)}}},[_vm._v(" "+_vm._s(_vm.event.canCancel.canCancelDisplayTitle)+" ")]):_vm._e()],1):_vm._e()],1)]),_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('h4',[_vm._v("주의사항 및 환불규정")])])],1)],1),_c('v-divider',{staticClass:"my-4"})],1),_c('v-card-text',{staticClass:"px-0"},[_c('h4',[_vm._v("주의사항")]),_c('ul',{staticClass:"mb-5"},[_c('li',[_vm._v(" 상황에 따라 사전 공지 없이 할인이 조기 마감되거나 연장될 수 있습니다. ")]),_c('li',[_vm._v(" 천재지변, 폐업 등 서비스 중단이 불가피한 상황에는 서비스가 종료될 수 있습니다. ")]),_c('li',[_vm._v(" 커리큘럼은 제작 과정에서 일부 추가, 삭제 및 변경될 수 있습니다. ")])]),_c('h4',[_vm._v("환불규정")]),_c('ul',{staticClass:"mb-2"},[_c('li',[_vm._v("사전 접수 기간 중: 전액 환불")]),_c('li',[_vm._v(" 사전접수 기간 종료 후 ~ 교육 1일 전: 등록비 5% 내 공제 후 환불 ")]),_c('li',[_vm._v("교육 당일 및 이후: 환불 불가")])]),_c('p',[_vm._v(" * 보다 자세한 환불 규정은 홈페이지 이용 약관 환불 정책에서 확인 가능합니다. ")])])],1)],1):_vm._e()],1),_c('my-page-application-board-event-detail-dialog',{attrs:{"is-open-event-detail-dialog":_vm.isOpenEventDetailDialog},on:{"update:isOpenEventDetailDialog":function($event){_vm.isOpenEventDetailDialog=$event},"update:is-open-event-detail-dialog":function($event){_vm.isOpenEventDetailDialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }